import "./loading.css";

const LoadingScreen = () => {
  return (
    // <>Loading...</>
    <div class='container'>
  <div class='loader'>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--dot'></div>
    <div class='loader--text'></div>
  </div>
</div>
  )
}
export default LoadingScreen
