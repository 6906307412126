import { useEffect, useState } from "react";
import Modal from "react-modal";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { removeError, setError, uiCloseModal } from "../../actions/ui";
import { useDispatch, useSelector } from "react-redux";
import {
  eventClearActive,
  eventStartAddNew,
  eventStartUpdate,
} from "../../actions/event";
import Alert from "../ui/Alert";

Modal.setAppElement("#root");
const nowInitial = moment().minutes(0).seconds(0).add(1, "hour");
const nowEnd = nowInitial.clone().add(1, "hour");

const initEvent = {
  title: "",
  KWHO: "",
  KWHC: "",
  KVAHO: "",
  KVAHC: "",
  KVA: "",
  hours: "",
  notes: "",
  start: nowInitial.toDate(),
  end: nowEnd.toDate(),
};

const CalendarModal = () => {
  const dispatch = useDispatch();

  const { ui, calendar } = useSelector((state) => state);
  const { modalOpen, msgError } = ui;
  const { activeEvent } = calendar;

  const [formValues, setFormValues] = useState(initEvent);
  const { notes, title,KWHO, KWHC, KVAHO, KVAHC, KVA, start, end } = formValues;
  useEffect(() => {
    if (activeEvent) {
      setFormValues(activeEvent);
    } else {
      setFormValues(initEvent);
    }
  }, [activeEvent]);

  const handleInputChange = ({ target }) => {
  
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const closeModal = () => {
    if (modalOpen) {
      dispatch(eventClearActive());
      dispatch(uiCloseModal());
    }
  };

  const handleStartDateChange = (e) => {
    setFormValues({
      ...formValues,
      start: e,
    });
  };

  const handleEndDateChange = (e) => {
    setFormValues({
      ...formValues,
      end: e,
    });
  };
  // function calc(){
  //   var kwho = parseFloat(document.getElementById("KWHO").value);
  //   var kwhc = parseFloat(document.getElementById("KWHC").value);
  //   var kavho = parseFloat(document.getElementById("KVAHO").value);
  //   var kavhc = parseFloat(document.getElementById("KVAHC").value);
  //   var kva = parseFloat(document.getElementById("KVA").value);
  //   var kwhtotal = 2*(kwhc-kwho);
  //   document.getElementById("KWH").value = kwhtotal;
  //   var kvahtotal = 2*(kavhc-kavho);
  //   document.getElementById("KVAH").value = kvahtotal;
  //   var loss = kvahtotal - kwhtotal;
  //   document.getElementById("loses").value = loss;
  //   var kvatotal = kva*2;
  //   document.getElementById("KVAT").value = kvatotal;
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid()) return;

    if (activeEvent && activeEvent.id) {
      // Update
      dispatch(eventStartUpdate(formValues));
    } else {
      // Create new
      dispatch(eventStartAddNew(formValues));
    }

    closeModal();
  };

  const isFormValid = () => {
    if (title.trim().length === 0) {
      dispatch(setError("Title is required"));
      return false;
    } else if (title.trim().length > 32) {
      dispatch(setError("Title length must be max 32 characters"));
      return false;
    } else if (moment(start).isSameOrAfter(moment(end))) {
      dispatch(setError("End date must be after start date"));
      return false;
    } else if (notes && notes.trim().length > 128) {
      dispatch(setError("Notes length must be max 128 characters"));
      return false;
    }
    dispatch(removeError());
    return true;
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
      contentLabel="Event modal"
      className="modal"
      overlayClassName="modal__background"
    >
      <h1 className="modal__title">
        {activeEvent ? "Edit Record" : "New Record"}
      </h1>
      <hr />
      <form className="form" onSubmit={handleSubmit}>
        {msgError && <Alert type="error" description={msgError} />}
        <div className="form__field">
          <label className="form__label">Start date</label>
          <DateTimePicker
            onChange={handleStartDateChange}
            value={start}
            className="form__input"
          />
        </div>
        <div className="form__field">
          <label className="form__label">End date</label>
          <DateTimePicker
            onChange={handleEndDateChange}
            value={end}
            minDate={start}
            className="form__input"
          />
        </div>
        <hr />
        <div className="form__field">
          <label style={{color: "#FF9209"}}  htmlFor="title" className="form__label">
            Record title<span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="title"
            name="title"
            placeholder="New Record"
            value={title}
            onChange={handleInputChange}
          />
        </div>
        <div style={{display: "flex", gap: "10px"}}>
        <div className="form__field">
          <label style={{color: "#FF9209"}}  htmlFor="KWH" className="form__label">
            KWH(Opening)<span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KWHO"
            name="KWHO"
            placeholder="KWH Opening"
            value={KWHO}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__field">
          <label style={{color: "#FF9209"}}  htmlFor="KVAH" className="form__label">
            KWH(CLosing)<span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KWHC"
            name="KWHC"
            placeholder="KWH Closing"
            value={KWHC}
            onChange={handleInputChange}
          />
        </div>
        </div>
        <div style={{display: "flex", gap: "10px"}}>
        <div className="form__field">
          <label style={{color: "#FF9209"}} htmlFor="KWH" className="form__label">
            KVAH(Opening) <span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KVAHO"
            name="KVAHO"
            placeholder="KVAH Opening"
            value={KVAHO}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__field">
          <label style={{color: "#FF9209"}}  htmlFor="KVAH" className="form__label">
            KVAH(CLosing)<span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KVAHC"
            name="KVAHC"
            placeholder="KVAH Closing"
            value={KVAHC}
            onChange={handleInputChange}
          />
        </div>
        </div>
        <div className="form__field">
          <label style={{color: "#FF9209"}}  htmlFor="loses" className="form__label">
            KVA<span style={{color: "#FF9209"}}>*</span>
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KVA"
            name="KVA"
            placeholder="KVA"
            value={KVA}
            onChange={handleInputChange}
          />
        </div>
        {/* <span style={{width: "20px", marginBottom: "10px",}} className="btn btn-primary btn--block" onClick={calc}>
           <span style={{marginLeft: "-12px"}}>calc</span>
        </span> */}
        <div style={{display: "flex", gap: "10px"}}>
        <div className="form__field">
          <label htmlFor="KWH" className="form__label">
            Total KWH
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KWH"
            name="KWH"
            placeholder="Total KWH"
            value={2*(KWHC-KWHO)}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__field">
          <label htmlFor="KVAH" className="form__label">
            Total KVAH
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KVAH"
            name="KVAH"
            placeholder="Total KVAH"
            value={2*(KVAHC-KVAHO)}
            onChange={handleInputChange}
          />
        </div>
        </div>
        <div className="form__field">
          <label htmlFor="loses" className="form__label">
            Loses
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="loses"
            name="loses"
            placeholder="Total Loses"
            value={2*(KVAHC-KVAHO) - 2*(KWHC-KWHO)}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__field">
          <label htmlFor="loses" className="form__label">
            Total KVA
          </label>
          <input
            autoComplete="off"
            type="text"
            className="form__input"
            id="KVAT"
            name="KVAT"
            placeholder="Total KVA"
            value={2*KVA}
            onChange={handleInputChange}
          />
        </div>
        <div className="form__field">
          <label htmlFor="notes" className="form__label">
            Remarks
          </label>
          <textarea
            type="text"
            className="form__text-area"
            rows="5"
            id="notes"
            name="notes"
            value={notes}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <button className="btn btn-primary btn--block" type="submit">
          Save
        </button>
      </form>
    </Modal>
  );
};
export default CalendarModal;